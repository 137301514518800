import Cleave from 'cleave.js';



    $('.amount-input').toArray().forEach(function(field){
        new Cleave(field, {
            numeral: true,
            delimiter: '',
            onlyPositive: true
        })})

    $('.amount-input-decimal').toArray().forEach(function(field){
        new Cleave(field, {
            numeral: true,
            delimiter: '',
            onlyPositive: true
        })})

    $('.numeric-only').toArray().forEach(function(field){
        new Cleave(field, {
            numericOnly: true,
            delimiter: '',
            onlyPositive: true,
            numeralDecimalScale: 4
        })})
    // var oneOffInitialAmount = new Cleave('.one-off-initial-amount-input', {
    //     numeral: true,
    //     delimiter: ''
    //     // numeralThousandsGroupStyle: 'thousand'
    // });
    //
    // var totalAmountInput = new Cleave('.total-amount-input', {
    //     numeral: true,
    //     delimiter: ''
    //     // numeralThousandsGroupStyle: 'thousand'
    // });
    //
    // var initialAmountInput = new Cleave('.initial-amount-input', {
    //     numeral: true,
    //     delimiter: ''
    //     // numeralThousandsGroupStyle: 'thousand'
    // });
    //
    // var monthlyAmountInput = new Cleave('.monthly-amount-input', {
    //     numeral: true,
    //     delimiter: ''
    //     // numeralThousandsGroupStyle: 'thousand'
    // });

